<template>
  <v-card flat class="pa-4">
    <v-tabs v-model="tab" color="primary" center-active @change="tabValue">
      <v-tab
        ><v-icon class="pr-2">mdi-account-check</v-icon>
        {{ $t("master_data.teacher.tab1_title") }}</v-tab
      >
      <v-tab
        ><v-icon class="pr-2">mdi-account-remove</v-icon>
        {{ $t("master_data.teacher.tab2_title") }}</v-tab
      >
      <v-tab
        ><v-icon class="pr-2">mdi-account-plus</v-icon>
        {{ $t("master_data.teacher.tab3_title") }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <Data type="teacher" />
        <AddData
          :title="$t('master_data.teacher.dialog_1.title')"
          :content1="$t('master_data.teacher.dialog_1.content_1')"
          :content2="$t('master_data.teacher.dialog_1.content_2')"
          path="teacher-manual"
          type="teacher"
        />
      </v-tab-item>
      <v-tab-item>
        <Mutation :namaMenu="$t('app.teacher')" menuType="teacher" />
      </v-tab-item>
      <v-tab-item>
        <Candidate
          :namaMenu="$t('master_data.teacher.tab1_title')"
          menuType="teacher"
          :dataContentDialog="[
            $t('master_data.teacher.dialog.content_1'),
            $t('master_data.teacher.dialog.content_2'),
            $t('master_data.teacher.dialog.content_3')
          ]"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
export default {
  metaInfo: {
    title: `Master ${i18n.t("routes.teacher")}`,
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    AddData: () => import("../components/AddData"),
    Data: () => import("../components/Data"),
    Mutation: () => import("../components/Mutation"),
    Candidate: () => import("../components/Candidate")
  },
  methods: {
    tabValue(tab) {
      this.$router.push({ name: "Teacher", query: { tab: tab } });
    }
  },
  data() {
    return {
      tab: this.$route.query.tab | 0
    };
  },
  watch: {
    "$route.query.tab"(n) {
      if (n) this.tab = this.$route.query.tab;
    }
  },
  computed: mapGetters(["g_master_data_guru", "g_master_data_dummy"])
};
</script>
